body {
	margin: 0;
	padding: 0;
	font-family: 'Roboto', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	box-sizing: border-box;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.app {
	width: 100%;
	height: 100vh;
	background-color: rgb(245, 245, 245);
	position: relative;
}

.panel {
	width: 30%;
	float: left;
	height: 100vh;
	background-color: #4396ec;
	z-index: 9999;
	padding: 0 20px;
	box-sizing: border-box;
	box-shadow: 3px 3px 3px #e3e3e3;
	position: fixed;
	background-image: url('/bg.png');
	background-size: contain;
	background-position: bottom;
	background-repeat: no-repeat;
}

.panel.hide {
	-webkit-transition: 0.2s linear;
	transition: 0.2s linear;
	-webkit-transform: translateX(-100%);
	        transform: translateX(-100%);
}

.panel .toggle-btn {
	right: -30px;
	width: 30px;
	top: 30px;
	font-size: 30px;
	font-weight: 100;
	height: 50px;
	color: hsl(0, 0%, 50%);
	line-height: 50px;
	text-align: center;
	background: white;
	position: absolute;
	box-shadow: 3px 3px 3px #e3e3e3;
	cursor: pointer;
	-webkit-user-select: none;
	   -moz-user-select: none;
	    -ms-user-select: none;
	        user-select: none;
}

.map-wrapper {
	width: 70%;
	height: 100vh;
	float: right;
	box-sizing: border-box;
}

.map-wrapper.full-width {
	width: 100%;
}

.btn {
	position: relative;
	display: block;
	overflow: hidden;
	border-width: 0;
	outline: none;
	border-radius: 2px;
	font-size: 14px;
	height: 40px;
	width: 100px;
	box-shadow: 0 1px 4px rgba(0, 0, 0, 0.6);
	background-color: #f5f5f5;
	color: black;
	-webkit-transition: background-color 0.3s;
	transition: background-color 0.3s;
}

.btn:hover,
.btn:focus {
	background-color: white;
}

.btn > * {
	position: relative;
}

.btn span {
	display: block;
	padding: 12px 24px;
}

.btn:before {
	content: '';

	position: absolute;
	top: 50%;
	left: 50%;

	display: block;
	width: 0;
	padding-top: 0;

	border-radius: 100%;

	background-color: rgba(236, 240, 241, 0.3);

	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}

.btn:active:before {
	width: 120%;
	padding-top: 120%;

	-webkit-transition: width 0.2s ease-out, padding-top 0.2s ease-out;

	transition: width 0.2s ease-out, padding-top 0.2s ease-out;
}

.mobile-search {
	display: none;
}

.mobile-app-download-wrapper {
	margin-top: 40px;
}

.mobile-app-download-icon {
	display: block;
	width: 70%;
	margin-bottom: 20px;
}

.mobile-app-download-icon img {
	width: 200px;
	height: 60px;
}

.clear {
	clear: both;
}

@media only screen and (max-width: 600px) {
	.panel {
		width: 100%;
	}

	.mobile-search {
		display: block;
	}

	.map-wrapper {
		width: 100%;
	}

	.mobile-app-download-wrapper {
		width: 100%;
		margin-top: 40px;
	}

	.mobile-app-download-icon {
		margin-bottom: 10px;
		display: block;
		width: 120px;
	}
}

.spinner {
	text-align: center;
	width: 70px;
	text-align: center;
	position: absolute;
	top: 50%;
	left: 50%;
	margin: -35px;
}

.spinner > div {
	width: 18px;
	height: 18px;
	background-color: #333;

	border-radius: 100%;
	display: inline-block;
	-webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
	animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
	-webkit-animation-delay: -0.32s;
	animation-delay: -0.32s;
}

.spinner .bounce2 {
	-webkit-animation-delay: -0.16s;
	animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
	0%,
	80%,
	100% {
		-webkit-transform: scale(0);
	}
	40% {
		-webkit-transform: scale(1);
	}
}

@keyframes sk-bouncedelay {
	0%,
	80%,
	100% {
		-webkit-transform: scale(0);
		transform: scale(0);
	}
	40% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}

